<template>
  <div class="container">
    <h1>Мои ответы</h1>
    <div class="mb-3" v-if="allOffers.length > 0">
      <div class="row">
        <div class="col-md-12">
          <b-form-group label="Фильтр" class="mb-4">
            <b-form-radio-group v-model="filterOffersByState">
              <b-form-radio value="">Все</b-form-radio>
              <b-form-radio value="New">Не просмотрен</b-form-radio>
              <b-form-radio value="Viewed">Просмотрен</b-form-radio>
              <b-form-radio value="Reserved">Зарезервирован</b-form-radio>
              <b-form-radio value="Ended">Закрыт</b-form-radio>
              <b-form-radio value="Canceled">Отменён</b-form-radio>
              <b-form-radio value="HideC">Скрыт клиентом</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>
      <div class="row mb-4">
        <div
          class="col-md-6"
          v-for="(item, index) in filteredOffers"
          v-bind:key="index"
        >
          <div class="offer-item">
            <div class="row">
              <div class="col-auto" v-if="item['photo1']">
                <div class="offer-item__image">
                  <b-link :to="'/offers/' + item._id">
                    <img
                      :src="'https://vp.bw2api.ru/' + item['photo1']['url']"
                      alt=""
                      class="img-fluid"
                    />
                  </b-link>
                </div>
              </div>
              <div class="col" v-if="item['order']">
                <b-link
                  :to="'/offers/' + item._id"
                  class="offer-item__car-name"
                >
                  <div v-if="item.order.vehicle">
                    {{ item["order"]["vehicle"]["brand"] }}
                    {{ item["order"]["vehicle"]["model"] }}
                    {{ item["order"]["vehicle"]["year"] }}
                  </div>
                </b-link>
                <div class="offer-item__text">
                  <strong>Стоимость: </strong>
                  {{ item.price }}
                </div>
                <div class="offer-item__created">
                  {{ item.createdAt | moment("DD.MM.YY H:mm") }}
                </div>
                <div class="offer-item__state">
                  <span class="reserved" v-if="item.state == 'Reserved'">
                    Клиент забронировал запчасть
                  </span>
                  <span class="hidec" v-if="item.state == 'HideC'">
                    Клиент скрыл ваш ответ
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="text-center mb-5">
        <button
          class="btn btn-primary"
          @click="showMore"
          v-if="filteredOffers.length > countShow"
        >
          Показать ещё
        </button>
      </div> -->
    </div>
    <div v-else>
      <p>Ответов нет</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "offers",
  data() {
    return {
      countShow: 10,
      filterOffersByState: "",
    };
  },
  computed: {
    ...mapGetters(["allOffers", "profile"]),

    filteredOffers() {
      if (this.filterOffersByState) {
        let arrFiltered = this.allOffers.filter((item) => {
          return this.filterOffersByState == item.state;
        });
        return arrFiltered;
      } else {
        return this.allOffers;
      }
    },

    // offersShow() {
    //   return this.filteredOffers.slice(0, this.countShow);
    // },

    allStates() {
      let arrStates = [];
      this.allOffers.forEach((element) => {
        if (arrStates.indexOf(element.state) == -1) {
          arrStates.push(element.state);
        }
      });
      return arrStates;
    },
  },
  components: {},
  methods: {
    // showMore() {
    //   this.countShow += 10;
    // },
  },
  watch: {
    // filterOffersByState: function () {
    //   this.countShow = 10;
    // },
  },
};
</script>
